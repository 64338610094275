import { Link, navigate } from "gatsby";
import * as React from "react";
import { Helmet } from "react-helmet";

import Layout from "../../components/layout/Layout";
import Button from "../../components/Button";
import PageHeader from "../../components/PageHeader";
import PageSection from "../../components/PageSection";
import { SectionHeader, HelpItem } from "../../components/Services";
import TechBanner from "../../components/TechBanner";

const Page = () => {
    return (
        <Layout>
            <Helmet>
                <title>Business Software Solutions</title>
                <meta name="description" content="Line of business systems development."></meta>
            </Helmet>
            <PageHeader title="Business Software Solutions">
                Secure, high quality custom business software to empower you and your customers.
            </PageHeader>
            <div className="container mx-auto px-8 mt-5">
                <section>
                    <h2 className="text-xl text-primary-default">What is a custom software solution?</h2>
                    <p>
                        Business is increasingly built on technology. That technology is powered by great software.
                    </p>
                    <p className="mt-2">
                        In order for businesses to stand out and provide the best service possible to their customers, it
                        is becoming increasingly commonplace for technology and software to take a central role. Even businesses which
                        previously may not have found a need for such solutions are needing to adapt quickly.
                    </p>
                </section>

                <PageSection shadow header="When to consider custom software">
                    <p>
                        In this era of always-connected, technology driven business it can seem hard to imagine a case where the software for
                        your business can't simply be purchased off-the-shelf, but it can happen more frequently than you may think.
                    </p>
                    <SectionHeader>Nothing on the market fits the needs of your business</SectionHeader>
                    <p>
                        Are you doing something new/different in your industry?
                    </p>
                    <p>
                        Whilst software for common tasks such as
                        sending e-mail or word processing is easy to purchase off-the-shelf, if your business
                        has specialist or other industry-specific needs then options can quickly become limited. In such cases, having
                        your own custom solution designed and built can make a lot of sense.
                    </p>
                    <SectionHeader>New innovations or ideas may require new software solutions</SectionHeader>
                    <p>
                        Every industry and its needs are different, from booking in customers
                        via a new mobile app or website, to modelling complex manufacturing processes using the latest desktop PC environments.
                        Each of these is unique, and Eborsoft can help.
                    </p>
                    <ul className="list-disc list-outside ml-10 mt-2">
                        <li>Solutions are built to fit how you and your customers operate.</li>
                        <li>
                            Is a trade secret or patented methodology involved? No problem! We are fully security vetted and able to operate under
                            NDA to ensure confidentiality of your information and processes.
                        </li>
                        <li>
                            Industry agnostic - from telecommunications to chemical engineering, our team has successfully operated in a large number of
                            disparate industries and are able to translate your requirements into cutting edge business solutions.
                        </li>
                    </ul>
                    <SectionHeader>Response to competitors</SectionHeader>
                    <p>
                        If a competitor launches a disruptive service it may require quick and decisive action.
                    </p>
                    <p>
                        Eborsoft can help to analyse what your competitor is doing from a software perspective, and not only advise on how
                        to compete but also try to surpass the existing solution.
                    </p>
                </PageSection>
                <PageSection shadow header="Support and maintenance">
                    <SectionHeader>Long Term Support</SectionHeader>
                    <p>
                        When implementing software we not only make use of the latest technology, but take into account the long term viability of our technology choices.
                    </p>
                    <p>
                        Software which is supportable has much higher value; we research and vet the technologies we use to ensure they will not be rendered obsolete and will receive security
                        updates for a minimum of 3 years. If a provided solution later requires non-functional maintenance to ensure it stays supported we can perform this maintenance or
                        advise options. Alternatively, another development team could pick this up for you as the blueprints/source code to all solutions remain the property of your business.
                    </p>
                    <SectionHeader>No lock-in</SectionHeader>
                    <p>
                        Ownership of all software built stays with you. One of our key goals is to build long term relationships with our technology partners - however, if
                        your business decides to switch to an in house development team or even another third party, that's not a problem. All rights and source code belong
                        to you, and if required we can perform a formal handover to another team. If solutions we provide contain our own shared libraries or proprietary
                        algorithms, you are given permanent, perpetual rights to these along with all source code.
                    </p>
                </PageSection>
                <PageSection shadow header="How can Eborsoft help?">
                    <TechBanner />
                    <div className="mt-4">
                        We use the latest processes and technologies to ensure that you get the best solution possible.

                        <div>
                            <HelpItem title="Free initial consultation">
                                Whether you know what you need, or just want to understand the possibilities, why not <Link to="/contact">reach out</Link> for an initial consultation to
                                discuss your requirements free of charge? This can be as formal or informal as is required. We love talking
                                tech and would be excited to discuss your potential requirements!
                            </HelpItem>

                            <HelpItem title="Top notch solution design and implementation">
                                You bring the ideas and we'll fill in the gaps! Our team specialise in taking business requirements from any industry and
                                turning those into complete, workable system designs. From the smallest desktop application up to multi-tiered cloud based, scalable
                                solutions. We work to your requirements, both in terms of functionality and budget.
                            </HelpItem>

                            <HelpItem title="Built to your budget and timescales">
                                We plan around your time and financial budgets when working on your system design. We are able to use our expertise and extensive
                                library of in-house knowledge to lower costs and focus work on what matters to you. If the unexpected should happen and
                                plans are affected, we work with you to stay on track.
                            </HelpItem>

                            <HelpItem title="All platforms and devices covered">
                                Need a mobile app to allow your staff or customers to quickly access their data? A web based application or perhaps something you
                                need to deploy to your staff workstations? Eborsoft can provide applications that run on desktop, mobile or web.
                            </HelpItem>

                            <HelpItem title="Best in class technology choice">
                                Eborsoft specialises in Microsoft based technologies as well as the latest web technologies such as ReactJS.
                                When implementing a solution, we ensure that the technologies chosen are modern, standard and long-lasting. We can provide
                                cloud migration and management services if this is required.
                            </HelpItem>

                            <HelpItem title="Clear, upfront process">
                                We constantly work to our core values of <strong>communication</strong> and <strong>quality</strong>. As a customer and
                                technology partner, we include you at every stage of the process. At the end of the project we can hand off to your development team.
                            </HelpItem>

                            <HelpItem title="Secure source control">
                                Do you use an existing source control solution? Eborsoft can work with you to directly integrate and work with it.
                                If not, Eborsoft can manage your source control solution for you using either GitHub or Azure DevOps for a low
                                management charge.
                            </HelpItem>
                        </div>
                    </div>
                </PageSection>

                <div className="flex item-center">
                    <Button className="mx-auto mt-8" onClick={() => navigate("/contact")}>Interested? Contact Us</Button>
                </div>
            </div>
        </Layout>
    );
};

export default Page;
